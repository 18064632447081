import * as React from "react"
import { Layout } from "../components/Layout";
import { graphql } from 'gatsby';
import { PageTitle } from "../components/PageTitle";
import { Description } from "../components/Description";
import styled from "styled-components";

const GlossaryTerm = styled.h3`
  font-weight: 800;
  font-size: 24px;
  margin-top: 5px;
`;

export const Head = () => 
  <>
    <title>Glossary | Worldwide Lighthouses</title>
    <meta name="description" content="Explanations of all the terms required to understand the world of maritime navigation" />
  </>;

const GlossaryPage = ({ data }) => {
  const glossaryItems = data.glossaryItems.edges.map(edge => edge.node);

  return (
    <Layout>
      <PageTitle title="Glossary" />
      {glossaryItems.map(glossaryItem => 
        <Description>
          <GlossaryTerm>{glossaryItem.frontmatter.term}</GlossaryTerm>
          <div dangerouslySetInnerHTML={{ __html: glossaryItem.html }} />
        </Description> 
      )}
    </Layout>
  )
}

export const Query = graphql`
  query GlossaryQuery {
    glossaryItems: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/glossary/.*.md$/"}}
      sort: { order: ASC, fields: frontmatter___term }
    ) {
      edges {
        node {
          frontmatter {
            term
          }
          id,
          html
        }
      }
    }
  }
`;

export default GlossaryPage;
